import { Button, ErrorHandler, Modal, useGeneratedId } from '@flipgrid/flipkit';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CropModal from './CropModal';
import Dropzone, { links as dropzoneStyles } from './Dropzone';
import GlobalContext from '~/contexts/globalContext';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ReactCropProps } from 'react-image-crop';
import type { ImageUpload } from 'types';

export const links = () => [...dropzoneStyles()];

type Props = {
  'aria-describedby'?: string;
  'aria-label'?: string;
  dropzoneWidth?: string;
  circle?: boolean;
  cropOptions?: ReactCropProps['crop'];
  hasPhoto?: boolean;
  maxAspect?: number;
  minAspect?: number;
  onComplete?: (uploadResponse: ImageUpload) => void;
  onRequestClose: OnRequestCloseType;
  shortText?: boolean;
  successMessage: string;
  type: 'profile' | 'discoveryProfile' | 'discovery' | 'group' | 'topic';
};

const ImageCropper = ({
  'aria-describedby': ariaDescribedby = '',
  'aria-label': ariaLabel = '',
  circle,
  cropOptions,
  dropzoneWidth,
  hasPhoto,
  maxAspect,
  minAspect,
  onComplete,
  onRequestClose,
  shortText,
  successMessage,
  type,
}: Props) => {
  const { t } = useTranslation();
  const { announceLiveMessage } = useContext(GlobalContext);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [img, setImg] = useState<string>('');
  const [isValidFiletype, setIsValidFiletype] = useState(true);
  const [showCropModal, setShowCropModal] = useState(false);
  const filetypeErrorId = useGeneratedId() as string;

  const onDrop = (newFile: File) => {
    const isValidFile = ['image/jpeg', 'image/png'].includes(newFile.type);
    setIsValidFiletype(isValidFile);

    if (isValidFile) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImg(reader.result as string));
      reader.readAsDataURL(newFile);
      setFile(newFile);
      setShowCropModal(true);
    }
  };

  const InvalidMediaHandler = (
    <ErrorHandler
      id={filetypeErrorId}
      announceLiveMessage={announceLiveMessage}
      error={t('dropzone.unsupportedFileType')}
    />
  );

  return (
    <>
      {type === 'group' || type === 'topic' ? (
        <Modal onClose={onRequestClose}>
          <h1 className="fk-modalHeader text-center mb1">{t('shared.uploadAnImage')}</h1>
          <Dropzone
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedby}
            onDrop={onDrop}
            imageWidth={dropzoneWidth}
            imageHeight="900"
            type={type}
          />
          {!isValidFiletype && InvalidMediaHandler}
          <Modal.Actions className="mt2">
            <Button data-testid="imageCropper__button__cancel" onClick={onRequestClose} theme="secondary">
              {t('common.cancel')}
            </Button>
          </Modal.Actions>
        </Modal>
      ) : (
        <>
          <Dropzone
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedby}
            onDrop={onDrop}
            imageHeight="450"
            imageWidth={dropzoneWidth}
            type={type}
            shortText={shortText}
            hasPhoto={hasPhoto}
            errorMessageId={filetypeErrorId}
          />
          {!isValidFiletype && InvalidMediaHandler}
        </>
      )}

      {showCropModal && (
        <CropModal
          circle={circle}
          cropOptions={cropOptions}
          file={file}
          setFile={setFile}
          img={img}
          maxAspect={maxAspect}
          minAspect={minAspect}
          onComplete={imageUpload => {
            if (onComplete) onComplete(imageUpload);
            onRequestClose();
          }}
          onRequestClose={(message?: string) => {
            setShowCropModal(false);
            if (message && typeof message === 'string') announceLiveMessage(message);
          }}
          successMessage={successMessage}
          type={type}
        />
      )}
    </>
  );
};

export default ImageCropper;
