import { ButtonSubmit, ErrorHandler, Modal, Select, Option } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import classNames from 'classnames';
import { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ReactCrop from './ReactCrop';
import resourceRoutes from '~/constants/resourceRoutes';
import GlobalContext from '~/contexts/globalContext';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Crop, ReactCropProps } from 'react-image-crop';
import type { ImageUpload } from 'types';

type Props = {
  circle?: boolean;
  cropOptions?: ReactCropProps['crop'];
  file?: File;
  img: string;
  maxAspect?: number;
  minAspect?: number;
  onComplete: (uploadResponse: ImageUpload) => void;
  onRequestClose: OnRequestCloseType;
  setFile: (file?: File) => void;
  successMessage: string;
  type: 'profile' | 'discoveryProfile' | 'discovery' | 'group' | 'topic';
};

const CropModal = ({
  circle,
  cropOptions = { aspect: 1 },
  file,
  img,
  maxAspect,
  minAspect,
  onComplete,
  onRequestClose,
  setFile,
  successMessage,
  type,
}: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const { announceLiveMessage } = useContext(GlobalContext);
  const [crop, setCrop] = useState<ReactCropProps['crop']>(cropOptions);
  const [error, setError] = useState('');
  const imgRef = useRef<HTMLImageElement | undefined>(undefined);
  const dataURIRef = useRef('');

  // preset ratio state
  const enableAspectPresets = type === 'topic';
  const [ratio, setRatio] = useState<number>(1);
  const ratioOptions = [
    { label: '16:9', value: 16 / 9 },
    { label: '9:16', value: 9 / 16 },
    { label: '1:1', value: 1 },
    { label: '5:4', value: 5 / 4 },
    { label: '4:5', value: 4 / 5 },
    { label: '4:3', value: 4 / 3 },
    { label: '3:2', value: 3 / 2 },
    { label: '3:1', value: 3 / 1 },
  ];

  useEffect(() => {
    if (fetcher.type === 'done') {
      onComplete({ ...fetcher?.data?.data, dataURI: dataURIRef.current });
      setFile(undefined);
      if (onRequestClose) onRequestClose(successMessage);
      else announceLiveMessage(successMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher, onComplete, onRequestClose, setFile, successMessage]); // announceLiveMessage as a dependency causes an infinite loop

  const handleRatioChange = (newRatioString: string) => {
    const newRatio = parseFloat(newRatioString);
    setRatio(newRatio);

    if (imgRef.current) {
      const imageWidth = imgRef.current.width;
      const imageHeight = imgRef.current.height;

      // Calculate potential width and height based on the new ratio
      let width = imageWidth;
      let height = width / newRatio;

      // Check if calculated height exceeds image height, adjust if necessary
      if (height > imageHeight) {
        height = imageHeight;
        width = height * newRatio;
      }

      setCrop({
        unit: 'px',
        width,
        height,
        x: Math.max(0, (imageWidth - width) / 2), // Center crop horizontally
        y: Math.max(0, (imageHeight - height) / 2), // Center crop vertically
        aspect: newRatio,
      });
    }
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    imgRef.current = image;

    if (circle) {
      const { aspect } = cropOptions;
      const width = image.width > image.height ? (image.height / image.width) * 100 : 100;
      const height = image.height > image.width ? (image.width / image.height) * 100 : 100;
      const x = width === 100 ? 0 : (100 - width) / 2;
      const y = height === 100 ? 0 : (100 - height) / 2;

      setCrop({
        unit: '%',
        width,
        height,
        x,
        y,
        aspect,
      });
    }

    if (enableAspectPresets) {
      handleRatioChange('1');
    }

    // This is the modal type
    if (type === 'group') {
      const imageAspect = image.width / image.height;

      if (minAspect !== undefined && maxAspect !== undefined && imageAspect >= minAspect && imageAspect <= maxAspect) {
        setCrop({
          ...crop,
          width: image.width,
          height: image.height,
        });
      }
      // need to calc 3.5 ratio in px based on image size
      else {
        setCrop({
          ...crop,
          width: image.width,
          height: image.width * 0.285714,
        });
      }
    }

    return false;
  };

  const dataURItoBlob = (dataURI: string) => {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i += 1) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: file?.type || 'image/jpeg' });
  };

  const getCroppedImage = () => {
    const pixelRatio = 4;
    const { current: image } = imgRef;

    if (
      image &&
      (crop.width || crop.width === 0) &&
      (crop.height || crop.height === 0) &&
      (crop.x || crop.x === 0) &&
      (crop.y || crop.y === 0)
    ) {
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const width = crop.unit === '%' ? (crop.width / 100) * image.width : crop.width;
      const height = crop.unit === '%' ? (crop.height / 100) * image.height : crop.height;

      const x = crop.unit === '%' ? (crop.x / 100) * image.width : crop.x || 0;
      const y = crop.unit === '%' ? (crop.y / 100) * image.height : crop.y || 0;

      canvas.width = width * pixelRatio;
      canvas.height = height * pixelRatio;

      if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

        ctx.imageSmoothingEnabled = false;

        ctx.drawImage(image, x * scaleX, y * scaleY, width * scaleX, height * scaleY, 0, 0, width, height);
      }
      return canvas;
    }

    return undefined;
  };

  const submitCrop = () => {
    const formData = new FormData();
    formData.append('content_type', file?.type as string);
    formData.append('grid_image', (type === 'group').toString());
    formData.append('topic_image', (type === 'topic').toString());
    formData.append('discovery_image', (type === 'discovery' || type === 'discoveryProfile').toString());
    formData.append('profile_image', (type === 'profile').toString());

    const canvas = getCroppedImage();
    const dataURI = canvas?.toDataURL(file?.type || 'image/jpeg', 1.0);
    if (dataURI) {
      dataURIRef.current = dataURI;
      const blob = dataURItoBlob(dataURI);
      formData.append('blob', blob);
      fetcher.submit(formData, { method: 'post', action: resourceRoutes.uploads, encType: 'multipart/form-data' });
      return;
    }
    setError(t('cropModal.errorUploadingImage'));
  };

  const onClose = () => {
    setFile(undefined);
    setCrop(cropOptions);
    imgRef.current = undefined;
    if (onRequestClose) onRequestClose();
  };

  const onChange = (c: Crop, percentCrop: Crop) => {
    const newCrop = crop.unit === '%' ? percentCrop : c;
    const newAspect = newCrop.width / newCrop.height;
    if (newCrop.width === 0) return;

    if (ratio && enableAspectPresets) {
      // Adjust height based on the width and selected ratio
      const adjustedHeight = newCrop.width / ratio;
      setCrop({ ...newCrop, height: adjustedHeight });
    } else if (!maxAspect || !minAspect) {
      setCrop(newCrop);
    } else if (newAspect >= minAspect && newAspect <= maxAspect) {
      setCrop(newCrop);
    }
  };

  return (
    <Modal onClose={onClose}>
      <h1 className="fk-modalHeader text-center">{t('cropModal.cropYourPhoto')}</h1>
      <p className="fk-modalBody text-center">{t('cropModal.selectArea')}</p>

      {enableAspectPresets && (
        <Select label={t('cropModal.aspectRatio')} value={ratio.toString()} onChange={handleRatioChange}>
          {ratioOptions.map(option => (
            <Option key={option.label} value={option.value.toString()}>
              {option.label}
            </Option>
          ))}
        </Select>
      )}

      <div className={classNames(['text-center', { mt1: enableAspectPresets }])}>
        <ReactCrop src={img} onImageLoaded={onImageLoaded} crop={crop} onChange={onChange} circularCrop={circle} />
      </div>
      <ErrorHandler id="cropModal__errorHandler" error={error} />
      <Modal.Actions className="mt1">
        <ButtonSubmit
          variant="block"
          type="button"
          data-testid="cropModal__button__crop"
          loading={fetcher.state === 'submitting' || fetcher.type === 'actionReload'}
          disabled={!crop.width}
          onClick={submitCrop}
        >
          {t('shared.saveChanges')}
        </ButtonSubmit>
      </Modal.Actions>
    </Modal>
  );
};

export default CropModal;
