import { IcFluentImageAdd24Regular, useGeneratedId } from '@flipgrid/flipkit';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import dropzoneStyles from '~/styles/components/Cropping/Dropzone.css';

export const links = () => [{ rel: 'stylesheet', href: dropzoneStyles }];

type Props = {
  'aria-describedby'?: string;
  'aria-label'?: string;
  errorMessageId?: string;
  hasPhoto?: boolean;
  imageHeight: string;
  imageWidth?: string;
  onDrop: (arg0: File) => void;
  shortText?: boolean;
  type: 'profile' | 'discoveryProfile' | 'discovery' | 'group' | 'topic';
};

const Dropzone = ({
  'aria-describedby': ariaDescribedby,
  'aria-label': ariaLabel,
  errorMessageId,
  hasPhoto,
  imageHeight,
  imageWidth,
  onDrop,
  shortText,
  type
}: Props) => {
  const { t } = useTranslation();
  const fileRef = useRef<HTMLInputElement>(null);
  const idToUse = useGeneratedId();

  const handleDrop = () => {
    if (fileRef.current?.files) {
      const file = fileRef.current.files[0];
      const preview = window.URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        onDrop(file);
        // @ts-expect-error
        file.preview = preview;
        if (fileRef.current) fileRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  };

  if (type === 'profile')
  return (
    <>
        <input
        className="dropzone__uploaderInput"
        type="file"
        id={idToUse}
        name="dropzone__image"
        ref={fileRef}
        onChange={handleDrop}
        accept="image/png, image/jpeg"
        aria-label={t('dropzone.imageUploader')} />

        <label htmlFor={idToUse} className="dropzone">
          <IcFluentImageAdd24Regular className="dropzone__uploadIcon" height={32} width={32} />
          <p className="dropzone__displayText">{t('dropzone.dropOrClick')}</p>
        </label>
      </>);


  if (type === 'discoveryProfile')
  return (
    <div className="dropzone">
        <input
        type="file"
        ref={fileRef}
        onChange={handleDrop}
        accept="image/jpeg,image/png"
        id={idToUse}
        aria-label={ariaLabel || (hasPhoto ? t('dropzone.updatePhoto') : t('dropzone.uploadPhoto'))}
        aria-describedby={ariaDescribedby} />

      </div>);


  return (
    <div className="dropzone">
      <input
        type="file"
        ref={fileRef}
        onChange={handleDrop}
        accept="image/jpeg,image/png"
        id={idToUse}
        aria-label={ariaLabel || t('dropzone.imageUploader')}
        aria-describedby={`${idToUse}-describer ${idToUse}-describer-2 ${ariaDescribedby}`}
        aria-activedescendant={errorMessageId} // should correspond to the id you use in <ErrorHandler />
      />
      <label htmlFor={idToUse}>
        <h2 id={idToUse + '-describer'} className="fk-mt0">
          {t('dropzone.dropOrClick')}
        </h2>
        <p id={idToUse + '-describer-2'} className="fk-m0">
          {shortText ?
          t('dropzone.supportedTypes') :
          t('dropzone.supportedTypesCropAllowed', { width: imageWidth, height: imageHeight })}
        </p>
      </label>
    </div>);

};

export default Dropzone;