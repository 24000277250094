import { useTranslation } from 'react-i18next';
import LibraryReactCrop from 'react-image-crop';

import type { ReactCropProps } from 'react-image-crop';

const ReactCrop = ({ crop, onChange, onImageLoaded, src, ...rest }: ReactCropProps) => {
  const { t } = useTranslation();

  return (
    <LibraryReactCrop
      {...rest}
      crop={crop || {}}
      onChange={onChange}
      onImageLoaded={onImageLoaded}
      src={src}
      ariaLabels={{
        cropArea: t('reactCrop.cropArea'),
        nwDragHandle: t('reactCrop.nwDragHandle'),
        nDragHandle: t('reactCrop.nDragHandle'),
        neDragHandle: t('reactCrop.neDragHandle'),
        eDragHandle: t('reactCrop.eDragHandle'),
        seDragHandle: t('reactCrop.seDragHandle'),
        sDragHandle: t('reactCrop.sDragHandle'),
        swDragHandle: t('reactCrop.swDragHandle'),
        wDragHandle: t('reactCrop.wDragHandle'),
      }}
    />
  );
};

export default ReactCrop;
