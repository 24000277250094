import { Button, IcFluentSparkle24Filled, Tooltip } from '@flipgrid/flipkit';
import { useNavigate } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

import routes from '~/constants/routes';

import type { stringOrNumber } from 'types';

type Props = {
  groupId: stringOrNumber;
  topicId?: string;
  isUpdatingRoute?: boolean;
  showText?: boolean;
};

const TopicCopilotBtn = ({ groupId, topicId, isUpdatingRoute, showText }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loadModal = () => {
    if (isUpdatingRoute) {
      navigate(`${routes.GROUPS_ID_TOPICS_ID_UPDATE_FUNC(groupId, topicId)}?showTopicCopilotModal=true`);
    } else {
      navigate(`${routes.GROUPS_ID_TOPICS_NEW_FUNC(groupId)}?showTopicCopilotModal=true`);
    }
  };

  return (
    <Tooltip label={t('topicCopilotBtn.topicCopilot')}>
      <Button
        aria-label={t('topicCopilotBtn.openModal')}
        className="topicCopilotBtn__button"
        data-testid="topicCopilotBtn__button__openAI"
        icon={<IcFluentSparkle24Filled />}
        onClick={loadModal}
        size="36"
        theme="secondary"
      >
        {showText && t('topicCopilotBtn.topicCopilot')}
      </Button>
    </Tooltip>
  );
};

export default TopicCopilotBtn;
